import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
import tooltipExample from '../../../images/tooltips.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Tooltip`}</h1>
    <p>{`The Tooltip component appears as a popup design providing
a short text description of information about a feature when the user hovers over the item.`}</p>
    <ComponentPreview componentName="tooltip--default-story" minHeight="10rem" hasHTML hasAngular hasReact titleAttr="Tooltip Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use Tooltip when the feature needs to be explained in
further detail to walk users through a specific action.
It helps to draw the users' attention to a feature without
interrupting their workflow. They can currently only be used on icons.
They can be used on icons next to any feature such as icon buttons, inputs or tables.`}</p>
    <ul>
      <li parentName="ul">{`Appears when a user hovers or focuses on the Tooltip icon.`}</li>
      <li parentName="ul">{`Remains visible if the user hovers off of the icon onto the Tooltip.`}</li>
      <li parentName="ul">{`Can be toggled open by clicking or tapping on the Tooltip icon or with the Enter or Space keys.`}</li>
      <li parentName="ul">{`Remains in view until the component is no longer hovered or focused.`}</li>
      <li parentName="ul">{`Can be dismissed with the Escape key on the keyboard.`}</li>
    </ul>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Information should be discrete, short and concise.`}</li>
      <li parentName="ul">{`Do not use as a warning or alert.`}</li>
      <li parentName="ul">{`Do not include links or launch modals from a Tooltip.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Content within Tooltips must be text-based with a maximum character count of 140.`}</li>
      <li parentName="ul">{`The maximum width of Tooltips must be 290px.`}</li>
      <li parentName="ul">{`Text should be wrapped when the content is wider than the max width.`}</li>
      <li parentName="ul">{`The position of the design changes based on how close the feature is to the screen's edge such as:`}</li>
    </ul>
    <img className="sprk-u-mbm" src={tooltipExample} alt="The Spark Tooltip component depicted in four different orientations." />
    <h2>{`Accessibility`}</h2>
    <p>{`Tooltips are discoverable and 
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/WCAG20/#operable"
      }}>{`operable`}</a>{` 
by users that rely on a diverse set of established navigational means. Also, in compliance with 
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/content-on-hover-or-focus.html"
      }}>{`1.4.13: Content on Hover or Focus`}</a>{` 
WCAG rules, we make sure that any content appearing on hover or focus is dismissible and persistent.`}</p>
    <ul>
      <li parentName="ul">{`Must be accessible by mouse, pointer devices, keyboard-only, screen reader, zoom software and any other assistive technology.`}</li>
      <li parentName="ul">{`Allow the mouse to easily move over the Tooltip without dismissing it. `}</li>
      <li parentName="ul">{`Complex content should be browsable line by line using screen readers. `}</li>
      <li parentName="ul">{`Always include a visible label within UI controls.`}</li>
      <li parentName="ul">{`Interactive elements must be reachable using keyboard. `}</li>
      <li parentName="ul">{`Tooltips use `}<inlineCode parentName="li">{`aria-describedby`}</inlineCode>{` and `}<inlineCode parentName="li">{`id`}</inlineCode>{` to establish a relationship between the trigger and content for screen readers and other assistive technology.`}</li>
      <li parentName="ul">{`Tooltips use `}<inlineCode parentName="li">{`aria-hidden`}</inlineCode>{` so they are unreachable by screen readers when the Tooltip is not expanded.`}</li>
      <li parentName="ul">{`Add text alternatives to icon buttons to express action user is taking.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      